jQuery(function ($) {
    $(document).ready(function () {
        // Mobile menu
        $('.header__mobile-btn').on('click touched', function () {
            $('.mobile-nav').toggleClass('mobile-nav--active')
        })

        $('.mobile-nav__close-btn').on('click touched', function () {
            $('.mobile-nav').toggleClass('mobile-nav--active')
        })

        // Features page slider
        $('.features-slider__list').slick({
            centerMode: true,
            centerPadding: '400px',
            slidesToShow: 1,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        variableWidth: true,
                        centerMode: true,
                        centerPadding: '10%'
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: true,
                        centerMode: false,
                        centerPadding: '0'
                    }
                }
            ]
        })

        // Pricing page toggle price
        $('.pricing__toogle-btn').on('click touched', function () {
            $('.pricing__toogle-btn').removeClass('pricing__toogle-btn--active')

            $(this).addClass('pricing__toogle-btn--active')

            if ($(this).hasClass('pricing__toogle-btn--monthly')) {
                $('.pricing__price').each(function () {
                    $(this).find('.pricing__price-amount').text("$" + $(this).attr('data-monthly-price'))
                })

                $('.pricing__paid-period').html("PAID MONTHLY")
                $('.pricing__btn').each(function () {
                    $(this).attr('href', $(this).attr('data-monthly-url'))
                })
            }

            if ($(this).hasClass('pricing__toogle-btn--annual')) {
                $('.pricing__price').each(function () {
                    $(this).find('.pricing__price-amount').text("$" + $(this).attr('data-annual-price'))
                })

                $('.pricing__paid-period').html("PAID ANNUALLY")
                $('.pricing__btn').each(function () {
                    $(this).attr('href', $(this).attr('data-annual-url'))
                })
            }
        })
    })
})